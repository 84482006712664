<template>
  <AppProvider>
    <RmaCentra />
  </AppProvider>
</template>

<script>
import RmaCentra from './components/RmaCentra.vue'
import { AppProvider } from "@ownego/polaris-vue";

export default {
  name: 'App',
  components: {
    RmaCentra,
    AppProvider
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  padding: 15px;
}
</style>
